<template>
  <div class="main">
    <banner-swiper class="index">
      <template>
        <swiper-slide>
          <div class="banner-cover sign1" />
          <div class="banner-desc index">
            <div class="icon" />
            <div class="title">小程序定制服务</div>
            <div class="text">您的需求就是我们的行动纲领<br>资深团队时刻准备为您量身定做最适合的小程序</div>
            <div class="btn-wrap">
              <!-- <button class="btn red size-default change">立即咨询</button> -->
              <button class="btn hyaline size-default change" @click="$router.push('/miniapp#case')">查看案例</button>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="banner-cover sign2" />
          <div class="banner-desc index">
            <div class="icon" />
            <div class="title">广告投放</div>
            <div class="text">投放精准 转化高效<br>让广告客户获得品效合一的转化投放效果</div>
            <!-- <div class="btn-wrap">
              <button class="btn red size-default change">立即咨询</button>
              <button class="btn hyaline size-default change" @click="$router.push('/miniapp#case')">查看案例</button>
            </div> -->
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="banner-cover sign3" />
          <div class="banner-desc index">
            <div class="icon" />
            <div class="title">媒体变现</div>
            <div class="text">支持主流媒体类型和接入形式<br>让互联网媒体轻松获得高效率变现</div>
            <!-- <div class="btn-wrap">
              <button class="btn red size-default change">立即咨询</button>
              <button class="btn hyaline size-default change" @click="$router.push('/miniapp#case')">查看案例</button>
            </div> -->
          </div>
        </swiper-slide>
      </template>
      <template slot="after">
        <div class="banner-mouse">
          <img src="@/assets/mouse.png" alt="请下滑">
          <img src="@/assets/mouse_d.png" alt="">
          <img src="@/assets/mouse_n.png" alt="">
        </div>
        <div class="english-run">FEYSOS</div>
      </template>
    </banner-swiper>
    <div class="wrap business">
      <div v-scroll-toggle="['enter']" class="chunk-head">
        <div>Main business</div>
        <div>主营业务</div>
      </div>
      <div class="chunk-main">
        <router-link v-scroll-toggle="['enter']" to="/miniapp">
          <div class="img-scale">
            <img src="@/assets/business_01.png" alt="">
          </div>
          <div>小程序定制</div>
          <p>资深团队，人均10+小程序项目开发经验，一切以您的需求为优先，只开发您想要的小程序，从售前到售后，全方位满足您的需求。</p>
        </router-link>
        <router-link v-scroll-toggle="['enter']" to="/advert">
          <div class="img-scale">
            <img src="@/assets/business_02.png" alt="">
          </div>
          <div>广告投放</div>
          <p>精准投放，高效转化</p>
          <p>易操作的自主广告管理</p>
        </router-link>
        <router-link v-scroll-toggle="['enter']" to="/media">
          <div class="img-scale">
            <img src="@/assets/business_03.png" alt="">
          </div>
          <div>媒体变现</div>
          <p>支持主流媒体类型和接入形式</p>
          <p>一对一媒介经理服务</p>
        </router-link>
      </div>
    </div>
    <!-- <div class="team">
      <div class="bg" />
      <div class="wrap">
        <div v-scroll-toggle="['enter']" class="chunk-head-deformity">
          <div>+</div>
          <div>团队</div>
          <div>team</div>
        </div>
        <view-swiper class="chunk-main">
          <swiper-slide v-for="(item, index) in teamData" :key="index" v-scroll-toggle="['enter']">
            <div class="head">
              <p>{{ item.positionEN }}</p>
              <p>{{ item.positionCN }}</p>
            </div>
            <div class="img-scale">
              <img src="@/assets/team/01.jpg">
            </div>
            <div class="name">{{ item.name }}</div>
            <p class="desc">{{ item.desc }}</p>
          </swiper-slide>
        </view-swiper>
      </div>
    </div> -->
  </div>
</template>

<script>
import BannerSwiper from '@/components/bannerSwiper'
// import ViewSwiper from '@/components/viewSwiper'

import scrollToggle from '@/directives/scrollToggle'

export default {
  name: 'Index',
  components: {
    BannerSwiper
    // ViewSwiper
  },
  directives: {
    scrollToggle
  },
  data() {
    return {
      teamData: [
        { positionEN: 'Rear End', positionCN: '后端', cover: '../../assets/team_01.jpg', name: '测试', desc: '测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试' },
        { positionEN: 'Rear End', positionCN: '后端', cover: '../../assets/team_01.jpg', name: '测试', desc: '测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试' },
        { positionEN: 'Rear End', positionCN: '后端', cover: '../../assets/team_01.jpg', name: '测试', desc: '测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试' },
        { positionEN: 'Rear End', positionCN: '后端', cover: '../../assets/team_01.jpg', name: '测试', desc: '测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试' },
        { positionEN: 'Rear End', positionCN: '后端', cover: '../../assets/team_01.jpg', name: '测试', desc: '测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试' },
        { positionEN: 'Rear End', positionCN: '后端', cover: '../../assets/team_01.jpg', name: '测试', desc: '测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试' },
        { positionEN: 'Rear End', positionCN: '后端', cover: '../../assets/team_01.jpg', name: '测试', desc: '测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试' },
        { positionEN: 'Rear End', positionCN: '后端', cover: '../../assets/team_01.jpg', name: '测试', desc: '测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-container{
  .banner-cover{
    &.sign1{
      transform-origin: 50% 0%;
      background-image: url('../../assets/banner_miniapp.jpg');
    }
    &.sign2{
      transform-origin: 50% 40%;
      background-image: url('../../assets/banner_advert.jpg');
    }
    &.sign3{
      transform-origin: 50% 50%;
      background-image: url('../../assets/banner_media.jpg');
    }
  }
  .banner-mouse{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 110px;
    z-index: 1;
    width: 21px;
    height: 53px;
    margin: 0 auto;
    opacity: .8;
    animation: fadeIn 1s ease 1.5s backwards;
    img{
      position: absolute;
      &:nth-of-type(1) {
        top: 0;
        left: 0;
        width: 21px;
        height: 35px;
      }
      &:nth-of-type(2) {
        top: 6px;
        left: 9px;
        width: 4px;
        height: 8px;
        animation: mouse 1s ease infinite alternate;
      }
      &:nth-of-type(3) {
        left: 2px;
        bottom: 0;
        width: 18px;
        height: 11px;
        animation: mouse 1s ease infinite alternate;
      }
    }
    @keyframes mouse{
      from{
        transform: translateY(-2px);
      }
      to{
        transform: translateY(3px);
      }
    }
    @media screen and (max-height: 700px) {
      bottom: 60px;
    }
  }
}
.business{
  padding-top: 130px;
  .chunk-main{
    display: flex;
    justify-content: space-between;
    a{
      display: flex;
      width: 30%;
      margin-bottom: 50px;
      padding-bottom: 30px;
      flex-direction: column;
      align-items: center;
      visibility: hidden;
      transition: all .3s;
      &:hover{
        background-color: rgba(#fff, .1);
        transform: translateY(-20px);
        box-shadow: 0 10px 20px 0 rgba(#000, .2);
        img{
          transform: scale(1.1);
        }
      }
      &.enter{
        visibility: visible;
        animation: fadeInUp .6s backwards;
        &:nth-of-type(1) {
          animation-delay: 0s;
        }
        &:nth-of-type(2) {
          animation-delay: .2s;
        }
        &:nth-of-type(3) {
          animation-delay: .4s;
        }
      }
    }
    div{
      font-size: 24px;
      &.img-scale{
        padding-top: 62%;
      }
      &:nth-of-type(2){
        margin: 30px 0 20px;
        color: $themeColorActive;
      }
    }
    p{
      max-width: 100%;
      padding: 0 10px;
      text-align: justify;
      line-height: 1.75em;
      font-size: 18px;
      color: $themeColor;
    }
    img{
      transform: scale(1.02);
      transition: all .3s;
    }
  }
  @media screen and (max-width: 800px) {
    padding-top: 80px;
    .chunk-main{
      flex-direction: column;
      align-items: center;
      a{
        width: 80%;
        &.enter{
          animation-delay: 0s !important;
        }
      }
      div{
        font-size: 18px;
      }
      p{
        font-size: 14px;
      }
    }
  }
}
.team{
  position: relative;
  overflow: hidden;
  >.bg{
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    bottom: -1px;
    z-index: 0;
    background-image: url('../../assets/team_bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    box-shadow: 0 0 120px 120px rgba($themeBgColor, 1) inset, 0 0 0 1000px rgba($themeBgColor, .82) inset;
    @media screen and (max-width: 800px) {
      box-shadow: 0 0 30px 30px rgba($themeBgColor, 1) inset, 0 0 0 1000px rgba($themeBgColor, .82) inset;
    }
  }
  >.wrap{
    position: relative;
    z-index: 1;
    padding: 80px 0;
  }
  .chunk-head-deformity{
    display: flex;
    padding-right: 22%;
    padding-bottom: 100px;
    justify-content: flex-end;
    visibility: hidden;
    &.enter{
      visibility: visible;
      animation: slideInLeft 1s ease 0s both;
    }
    div{
      line-height: 1em;
      writing-mode: vertical-rl;
      &:nth-of-type(1) {
        font-size: 36px;
        color: #ff0000;
      }
      &:nth-of-type(2) {
        text-indent: .25em;
        font-size: 32px;
        color: $themeColorActive;
      }
      &:nth-of-type(3) {
        text-indent: 2.5em;
        line-height: 2em;
        font-family: 'gaoel';
        text-transform: Uppercase;
        letter-spacing: 4px;
        font-size: 16px;
        color: $themeColor;
      }
    }
  }
  .chunk-main{
    height: 580px;
    @media screen and (max-width: 950px) {
      height: 520px;
    }
    @media screen and (max-width: 800px) {
      width: 80%;
      height: 600px;
      margin: 0 auto;
    }
    @media screen and (max-width: 650px) {
      height: 550px;
    }
    @media screen and (max-width: 500px) {
      height: 540px;
    }
    @media screen and (max-width: 450px) {
      height: 620px;
    }
    @media screen and (max-width: 400px) {
      height: 600px;
    }
    @media screen and (max-width: 350px) {
      height: 550px;
    }
    .swiper-slide{
      box-sizing: border-box;
      border-radius: 10px;
      padding: 10px 0;
      transition: all .3s;
      visibility: hidden;
      &:hover{
        box-shadow: 0 0 100px 0 rgba(#000, .6) inset;
        img{
          transform: scale(1.1);
        }
      }
      &.enter{
        visibility: visible;
        animation: fadeInRightBig 1s backwards;
        @for $index from 1 through 4 {
          &:nth-of-type(#{$index}){
            animation-delay: ($index - 1) * .2s;
          }
        }
      }
    }
  }
  .head{
    width: 76%;
    margin: 0 auto;
    border-left: 2px solid #ff0000;
    padding-left: 15px;
    >p{
      line-height: 1em;
      &:nth-of-type(1){
        text-transform: Uppercase;
        font-size: 16px;
        color: $themeColor;
      }
      &:nth-of-type(2){
        margin-top: 5px;
        font-size: 18px;
        color: $themeColorActive;
      }
    }
  }
  .img-scale{
    margin-top: 30px;
    padding-top: 100%;
    background-color: $themeBarColor;
    img{
      transition: all .3s;
    }
  }
  .name{
    width: 76%;
    margin: 45px auto 0;
    font-size: 20px;
    color: $themeColorActive;
  }
  .desc{
    width: 76%;
    margin: 0 auto;
    line-height: 20px;
    font-size: 14px;
    color: $themeColor;
    @include text-overflow(6);
    &:before{
      display: block;
      padding: 7px 0;
      font-size: 26px;
      color: #ff0000;
      content: '+';
    }
  }
}
</style>
